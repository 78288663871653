import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';

/* eslint-disable @typescript-eslint/no-explicit-any */
const AuthorizedApolloProvider: React.FC<any> = ({ children, transactionId }) => {
  const { getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri: '/graphql',
  });

  const authLink = setContext(async () => {
    try {
      const token = await getAccessTokenSilently();
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Transaction-ID': transactionId,
        },
      };
    } catch (e) {
      // Force reload to show login
      if (e?.error === 'login_required') {
        location.reload();
      }
    }
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Tour: {
          keyFields: ['tourId'],
        },
        Feature: {
          keyFields: false,
        },
        PropertyUnits: {
          keyFields: ['id', 'isModel'],
        },
        UnitAvailability: {
          keyFields: ['id', 'floorplanUniqueID'],
        },
      },
    }),
    connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
