import React from 'react';
import * as Sentry from '@sentry/nextjs';

export type ErrorRender = (error: Error, errorInfo: React.ErrorInfo) => React.ReactNode;

interface Props {
  fallback?: ErrorRender | React.ReactNode;
}

interface State {
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state = {
    error: undefined,
    errorInfo: undefined,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo });

    Sentry.withScope((scope) => {
      scope.setExtra('errorInfo', errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      switch (true) {
        case typeof this.props.fallback === 'function':
          return (this.props.fallback as ErrorRender)(this.state.error!, this.state.errorInfo!);
        case !!this.props.fallback:
          return this.props.fallback;
        default:
          return <h1>Something went wrong.</h1>;
      }
    }

    return this.props.children;
  }
}
