import { ITheme, theme } from '@chakra-ui/core';
import colors from './colors';
import fonts from './fonts';
import fontSizes from './fontSizes';
import space from './space';

// Extend theme as needed
export default {
  ...theme,
  colors,
  fonts,
  fontSizes,
  space,
} as ITheme;
