import * as Constants from './constants';

import { MarkerProperties, MarkerType } from '../../_generated/types';

import { MapboxModeInterface } from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js';
import { Events } from '../constants/enums';

interface DrawMarkerState extends MarkerProperties {
  lastId: string | undefined;
  order?: number;
  addId: (id: string) => void;
  updateOrder?: (order: number) => void;
  setHasStart?: () => void;
}

interface DrawMarkerInterface extends MapboxModeInterface<DrawMarkerState> {
  stopDrawingAndRemove: (state: DrawMarkerState) => void;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const DrawMarker: DrawMarkerInterface = {} as any;

DrawMarker.onSetup = function (options) {
  const state = { ...options, lastId: undefined };
  this.clearSelectedFeatures();

  return state;
};

DrawMarker.stopDrawingAndRemove = function (state: DrawMarkerState) {
  if (state.lastId) {
    this.deleteFeature([state.lastId], { silent: true });
  }
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

DrawMarker.onTap = DrawMarker.onClick = function (state: DrawMarkerState, e) {
  const order = state.order ? { order: state.order } : {};
  const doesTakeOrderNumber = state.markerType === MarkerType.Unit || state.markerType === MarkerType.ModelUnit;
  const icon = doesTakeOrderNumber && state.order && state.order <= 5 ? `${state.icon}-${state.order}` : state.icon;
  const point = this.newFeature({
    type: 'Feature',
    properties: {
      icon,
      iconBase: state.icon,
      markerType: state.markerType ?? MarkerType.General,
      floor: state.floor,
      ...order,
      isHidden: !(!state.isHidden || state.markerType === MarkerType.Start) || state.markerType === MarkerType.Note,
      isOnRoute: !(!state.isOnRoute || state.markerType === MarkerType.Start),
      opacityIncreased: state.markerType === MarkerType.Note,
    },
    geometry: {
      type: 'Point',
      coordinates: [e.lngLat.lng, e.lngLat.lat],
    },
  });

  if (state.order && state.updateOrder) {
    state.updateOrder(state.order + 1);
  }
  if (state.markerType === MarkerType.Start && state.setHasStart) {
    state.setHasStart();
  }
  this.addFeature(point);

  const event = new Event(Events.ROUTE_UPDATE);
  document.dispatchEvent(event);
  const undoEvent = new Event(Events.ADD_FEATURE_TO_UNDO);
  document.dispatchEvent(undoEvent);
  state.lastId = point.id;
  this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [point.id] });
};

DrawMarker.toDisplayFeatures = function (_, geojson, display) {
  return display(geojson);
};

DrawMarker.onTrash = DrawMarker.stopDrawingAndRemove;

export default DrawMarker;
