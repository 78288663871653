import * as Constants from './constants';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isOfMetaType(type: any) {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return function (e: any) {
    const featureTarget = e.featureTarget;
    if (!featureTarget) return false;
    if (!featureTarget.properties) return false;
    return featureTarget.properties.meta === type;
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isShiftMousedown(e: any) {
  if (!e.originalEvent) return false;
  if (!e.originalEvent.shiftKey) return false;
  return e.originalEvent.button === 0;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isActiveFeature(e: any) {
  if (!e.featureTarget) return false;
  if (!e.featureTarget.properties) return false;
  return (
    e.featureTarget.properties.active === Constants.activeStates.ACTIVE &&
    e.featureTarget.properties.meta === Constants.meta.FEATURE
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isInactiveFeature(e: any) {
  if (!e.featureTarget) return false;
  if (!e.featureTarget.properties) return false;
  return (
    e.featureTarget.properties.active === Constants.activeStates.INACTIVE &&
    e.featureTarget.properties.meta === Constants.meta.FEATURE
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function noTarget(e: any) {
  return e.featureTarget === undefined;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isFeature(e: any) {
  if (!e.featureTarget) return false;
  if (!e.featureTarget.properties) return false;
  return e.featureTarget.properties.meta === Constants.meta.FEATURE;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isVertex(e: any) {
  const featureTarget = e.featureTarget;
  if (!featureTarget) return false;
  if (!featureTarget.properties) return false;
  return featureTarget.properties.meta === Constants.meta.VERTEX;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isShiftDown(e: any) {
  if (!e.originalEvent) return false;
  return e.originalEvent.shiftKey === true;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isEscapeKey(e: any) {
  return e.keyCode === 27;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isEnterKey(e: any) {
  return e.keyCode === 13;
}

export function isTrue() {
  return true;
}
