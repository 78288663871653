import map, { MapModel } from './map';
import prospect, { ProspectModel } from './prospect';

export interface StoreModel {
  map: MapModel;
  prospect: ProspectModel;
}
const model: StoreModel = {
  map,
  prospect,
};

export default model;
