import { DefaultTheme, theme } from '@chakra-ui/core';

import colors from '../constants/colors';

export default {
  ...theme.colors,
  orange: {
    50: '#ffeedd',
    100: '#ffd0b1',
    200: '#fab184',
    300: '#f79355',
    400: '#f37425', // Actual Color
    500: '#da5b0c',
    600: '#aa4707',
    700: '#7a3104',
    800: '#4b1c00',
    900: '#1f0700',
  },
  blue: {
    50: '#e5f5ff',
    100: '#cadbe8',
    200: '#adc1d3',
    300: '#8ea9be',
    400: '#6e90aa',
    500: '#557691', // Actual Color
    600: '#415c71',
    700: '#2c4252',
    800: '#172834',
    900: '#000f18',
  },
  gray: {
    50: '#f8f8f8',
    100: '#ebebeb',
    200: '#d3d3d3',
    300: '#b8b8b8',
    400: '#888888',
    500: '#707070',
    600: '#575759',
    700: '#3d3d3d',
    800: '#292929',
    900: '#0A0A0A',
  },
  ...colors,
} as DefaultTheme['colors'];
