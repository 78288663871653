export default {
  primary: '#f37425',
  primaryDark: '#1B2A33',
  primaryOpaque: 'rgba(243, 113, 32, 0.1)',
  lightBlue: '#557691',
  lightGray: '#f8f8f8',
  lightGrayHover: '#f3f3f3',
  primaryGray: '#d3d3d3',
  mediumGray: '#888',
  mediumGrayOpaque: 'rgba(136, 136, 136, 0.1)',
  darkGray: '#575759',
  alertRed: '#c70100',
  redOpaque: 'rgba(199, 0, 0, 0.1)',
  pathBlue: '#226DF1',
  blueOpaque: 'rgba(34, 109, 241, 0.1)',
  dividerLine: 'rgba(0,0,0,.05)',
  successGreen: '#2F9B34',
  greenOpaque: 'rgba(47, 155, 52, 0.1)',
  completePurple: '#7718F1',
  purpleOpaque: 'rgba(119, 24, 241, 0.1)',
  lightPink: '#FF61AD',
  pinkOpaque: 'rgba(255, 97, 173, 0.1)',
};
