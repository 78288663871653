import { Action, action } from 'easy-peasy';
import { Feature, Point, LineString } from 'geojson';
import { Feature as routeFeature, KeylessException } from '../../../_generated/types';

import { MapData } from '../../../_generated/types';
import { UnitType } from '../../components/Modals/AutoAddUnitsModal/hooks/useAutoAddUnits';
import { RouteFeature } from '../../components/Segments';
import { KeylessProvider } from '../../../server/constants';

export enum ModeType {
  MARKER,
  DRAW,
  NONE,
}

export enum PropertyEditorMode {
  NOTES = 'notes',
  APARTMENT_ID = 'apartmentId',
  ORDER = 'order',
  GATE_NUMBER = 'gateNumber',
  ELEVATOR_NUMBER = 'elevatorNumber',
}

export enum SaveModalMode {
  NOTES = 'Notes',
  NOTIFY = 'Notify Prospect',
}

export type Tile = {
  id: string;
  bounds?: {
    ne: [number, number];
    sw: [number, number];
  };
  bearing?: number;
  isKeyless?: boolean;
  hasAutoMarkerPlacement?: boolean;
  mapsIndoorsVenueId?: string | null;
  requiredAmenities?: string[];
  mapsPeopleEnabled?: boolean;
  shouldShowMapsPeopleExperiencePopUp?: boolean;
  unitKeylessProvider?: KeylessProvider;
  amenityKeylessProvider?: KeylessProvider;
  maximumTravelDistance?: number | null;
  mapsIndoorsApi?: string | null;
};

interface InstructionModal {
  showModal: boolean;
  setShowModal: Action<InstructionModal, boolean>;
}

interface MarkersModel {
  icon: string;
  setIcon: Action<MarkersModel, string>;
  startingId: string;
  setStartingPoint: Action<MarkersModel, { id: string; route: number }>;
  routeNumber: number;
  selectedMarker: Feature<Point | LineString> | undefined;
  setSelected: Action<MarkersModel, Feature<Point | LineString> | undefined>;
  hasStart: boolean;
  setHasStart: Action<MarkersModel, boolean>;
  nextOrderNum: number;
  setNextOrderNum: Action<MarkersModel, number>;
}

interface MinMaxType {
  min: number | null;
  max: number | null;
}

export enum UnitAvailabilityEnum {
  AVAILABLE = 'Available',
  LEASE_UP = 'Lease Up',
  FUTURE = 'Future',
  MODEL = 'Model',
}

interface FiltersType {
  propertyID?: string[];
  floorPlanBed?: number[];
  floorPlanBath?: number[];
  unitFloor?: number[];
  floorplanUniqueID?: string[];
  buildingNumber?: string[];
  sqFtRange?: MinMaxType;
  priceRange?: MinMaxType;
  unitHasDen?: boolean;
  unitHasLoft?: boolean;
  petFriendly?: boolean;
  handicapAccessible?: boolean;
  isAvailable?: boolean;
  availability?: UnitAvailabilityEnum[];
}

interface SubCommunityType {
  [key: string]: string;
}

export interface BaseFiltersType {
  bathrooms: number[];
  bedrooms: string[];
  floors: number[];
  subCommunities: SubCommunityType;
  floorPlans: SubCommunityType;
  buildingNumbers: string[];
  availability?: UnitAvailabilityEnum[];
}

interface AutoAddModel {
  filters: FiltersType;
  setFilters: Action<AutoAddModel, FiltersType>;
  resetFilters: Action<AutoAddModel>;
  baseFilters: BaseFiltersType;
  setBaseFilters: Action<AutoAddModel, Partial<BaseFiltersType>>;
  selectedUnits: UnitType[];
  setSelectedUnits: Action<AutoAddModel, UnitType[]>;
  mpGeneratedInstructions: string[][];
  setMPGeneratedInstructions: Action<AutoAddModel, string[][]>;
}

interface SegmentsModel {
  routeStops: RouteFeature[];
  routes: routeFeature[];
  setRouteStops: Action<SegmentsModel, RouteFeature[]>;
  setRoutes: Action<SegmentsModel, routeFeature[]>;
  mpRouteStops: routeFeature[];
  setMPRouteStops: Action<SegmentsModel, routeFeature[]>;
}

export interface MapModel {
  active: ModeType;
  instructionModal: InstructionModal;
  markers: MarkersModel;
  preview: PreviewModel;
  propertyEditor: PropertyEditorModel;
  saveModal: SaveModalModel;
  setMode: Action<MapModel, { name: 'trayMode' | 'active'; value: ModeType }>;
  setTile: Action<MapModel, Tile | null>;
  tile: Tile | null;
  trayMode: ModeType;
  isPreviousMap: boolean;
  setIsPreviousMap: Action<MapModel, boolean>;
  mapToUndo: MapToUndoModel;
  autoAdd: AutoAddModel;
  segments: SegmentsModel;
  keylessExceptions: KeylessException[] | null;
  setKeylessExeptions: Action<MapModel, KeylessException[]>;
}

interface MapToUndoModel {
  setMapData: Action<MapToUndoModel, { isBaseMap: boolean; mapData: MapData }>;
  mapData: MapData;
  baseMap: MapData;
  setCanUndoRedo: Action<MapToUndoModel, { type: 'undo' | 'redo'; value: boolean }>;
  canUndo: boolean;
  canRedo: boolean;
}

interface PreviewModel {
  isOpen: boolean;
  setPreviewIsOpen: Action<PreviewModel, { isOpen: boolean; mapData?: MapData }>;
  previousData: MapData | undefined;
  visibleTour: string;
  setVisibleTour: Action<PreviewModel, string>;
}

interface PropertyEditorModel {
  isOpen: boolean;
  onOpen: Action<PropertyEditorModel, PropertyEditorMode>;
  onClose: Action<PropertyEditorModel>;
  editorMode: PropertyEditorMode;
}

interface SaveModalModel {
  isOpen: boolean;
  onOpen: Action<SaveModalModel, SaveModalMode>;
  onClose: Action<SaveModalModel>;
  saveMode: SaveModalMode;
}

const mapModel: MapModel = {
  active: ModeType.NONE,
  instructionModal: {
    showModal: false,
    setShowModal: action((state, payload) => {
      state.showModal = payload;
    }),
  },
  markers: {
    icon: '📍',
    setIcon: action((state, payload) => {
      state.icon = payload;
    }),
    startingId: '',
    setStartingPoint: action((state, payload) => {
      state.startingId = payload.id;
      state.routeNumber = payload.route;
    }),
    routeNumber: 0,
    selectedMarker: undefined,
    setSelected: action((state, payload) => {
      state.selectedMarker = payload;
    }),
    nextOrderNum: 1,
    setNextOrderNum: action((state, payload) => {
      state.nextOrderNum = payload;
    }),
    hasStart: false,
    setHasStart: action((state, payload) => {
      state.hasStart = payload;
    }),
  },
  preview: {
    isOpen: false,
    previousData: undefined,
    setPreviewIsOpen: action((state, payload) => {
      state.isOpen = payload.isOpen;
      if (payload.isOpen) {
        state.previousData = payload.mapData;
      } else {
        state.visibleTour = '';
      }
    }),
    visibleTour: '',
    setVisibleTour: action((state, payload) => {
      state.visibleTour = payload;
    }),
  },
  propertyEditor: {
    isOpen: false,
    onOpen: action((state, payload) => {
      state.isOpen = true;
      state.editorMode = payload;
    }),
    onClose: action((state) => {
      state.isOpen = false;
    }),
    editorMode: PropertyEditorMode.NOTES,
  },
  saveModal: {
    isOpen: false,
    onOpen: action((state, payload) => {
      state.isOpen = true;
      state.saveMode = payload;
    }),
    onClose: action((state) => {
      state.isOpen = false;
    }),
    saveMode: SaveModalMode.NOTIFY,
  },
  setMode: action((state, payload) => {
    state[payload.name] = payload.value;
  }),
  setTile: action((state, payload) => {
    state.tile = payload ? { ...state.tile, ...payload } : null;
  }),
  tile: null,
  trayMode: ModeType.NONE,
  isPreviousMap: false,
  setIsPreviousMap: action((state, payload) => {
    state.isPreviousMap = payload;
  }),
  mapToUndo: {
    setMapData: action((state, payload) => {
      if (payload.isBaseMap) {
        state.baseMap = payload.mapData;
      } else {
        state.mapData = payload.mapData;
      }
    }),
    mapData: {},
    baseMap: {},
    canUndo: false,
    canRedo: false,
    setCanUndoRedo: action((state, { type, value }) => {
      if (type === 'undo') {
        state.canUndo = value;
      } else {
        state.canRedo = value;
      }
    }),
  },
  autoAdd: {
    filters: {
      propertyID: undefined,
      floorPlanBed: undefined,
      floorPlanBath: undefined,
      unitFloor: undefined,
      floorplanUniqueID: undefined,
      buildingNumber: undefined,
      sqFtRange: undefined,
      priceRange: undefined,
      unitHasDen: undefined,
      unitHasLoft: undefined,
      petFriendly: undefined,
      handicapAccessible: undefined,
      isAvailable: undefined,
    },
    setFilters: action((state, filters) => {
      state.filters = { ...state.filters, ...filters };
    }),
    resetFilters: action((state) => {
      state.filters = {};
    }),
    baseFilters: {
      bathrooms: [],
      bedrooms: [],
      floors: [],
      subCommunities: {},
      floorPlans: {},
      buildingNumbers: [],
    },
    setBaseFilters: action((state, filters) => {
      state.baseFilters = { ...state.baseFilters, ...filters };
    }),
    selectedUnits: [],
    setSelectedUnits: action((state, units) => {
      state.selectedUnits = units;
    }),
    mpGeneratedInstructions: [],
    setMPGeneratedInstructions: action((state, instructions) => {
      state.mpGeneratedInstructions = instructions;
    }),
  },
  segments: {
    routeStops: [],
    routes: [],
    setRouteStops: action((state, payload) => {
      state.routeStops = payload;
    }),
    setRoutes: action((state, payload) => {
      state.routes = payload;
    }),
    mpRouteStops: [],
    setMPRouteStops: action((state, payload) => {
      state.mpRouteStops = payload;
    }),
  },
  keylessExceptions: null,
  setKeylessExeptions: action((state, payload) => {
    state.keylessExceptions = payload;
  }),
};

export default mapModel;
