import { Action, Computed, action, computed } from 'easy-peasy';

import { Prospect } from '../../../_generated/types';

export interface ProspectModel {
  searched: Prospect[];
  setSearched: Action<ProspectModel, Prospect[]>;
  email: string;
  setEmail: Action<ProspectModel, string>;
  prospectByTour: Computed<ProspectModel, (tourId: string) => Prospect | undefined>;
  tourIdToDelete: string;
  setTourId: Action<ProspectModel, string>;
  tourIdToRevise: string;
  setTourIdToRevise: Action<ProspectModel, string>;
}

const prospectModel: ProspectModel = {
  searched: [],
  setSearched: action((state, payload) => {
    state.searched = payload;
  }),
  email: '',
  // Not being used
  prospectByTour: computed(
    (state) => (tourId: string) =>
      state.searched.find((val) => val.tours?.findIndex((tour) => tour?.tourId === tourId) !== -1),
  ),
  tourIdToDelete: '',
  setTourId: action((state, payload) => {
    state.tourIdToDelete = payload;
  }),
  tourIdToRevise: '',
  setTourIdToRevise: action((state, payload) => {
    state.tourIdToRevise = payload;
  }),
  setEmail: action((state, payload) => {
    state.email = payload;
  }),
};

export default prospectModel;
