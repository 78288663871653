export enum DeleteStatus {
  DELETED,
  ERROR,
  UNSTARTED,
}

export enum ReviseStatus {
  REVISED,
  ERROR,
  UNSTARTED,
}

export enum BlockStatus {
  UNSTARTED,
  BLOCKING,
  BLOCKED,
  ERROR,
}

export enum ToolbarMode {
  FULL,
  RESTRICTED,
  NONE,
}

export enum MapsPeopleIcons {
  POOL = 'Pool',
  FITNESS = 'Fitness',
  DOG_PARK = 'Dog Park',
  CLUBHOUSE = 'Clubhouse',
  LAUNDRY = 'Laundry',
  PARCEL = 'Parcel',
  I_LOUNGE = 'iLounge',
  MARKET = 'Market',
}

export enum Icons {
  POOL = 'pool',
  FITNESS = 'fitness',
  DOG_PARK = 'dog_park',
  LOUNGE = 'lounge',
  LAUNDRY = 'laundry',
  PARCEL = 'parcel',
  INTERNET = 'internet',
  MARKET = 'market',
}

export enum Events {
  PREVENT_SAVE = 'prevent-save',
  MP_VALIDATE_KEYLESS = 'mp-validate-keyless',
  ROUTE_UPDATE = 'route-update',
  ADD_FEATURE_TO_UNDO = 'add-feature-to-undo',
  REDO = 'redo',
  UNDO = 'undo',
}

export enum MPRouteContext {
  OUTSIDE = 'Outside',
  INSIDE_BUILDING = 'InsideBuilding',
  POOL = 'Pool',
}
